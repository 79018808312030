import React, { useEffect, useState } from 'react';

const ResultsCSV = ({ currentTitle }) => {
  const [isVisible, setIsVisible] = useState(false);

  const downloadCSV = (csv, filename) => {
    const csvData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    const link = document.createElement('a');
    link.setAttribute('href', csvData);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportTableToCSV = (html, filename) => {
    const csv = [];
    const rows = html;

    for (let i = 0; i < rows.length; i++) {
      const row = [],
        cols = rows[i].querySelectorAll('td, th');

      for (let j = 0; j < cols.length; j++) row.push(cols[j].innerText);

      csv.push(row.join(','));
    }
    downloadCSV(csv.join('\n'), filename);
  };

  const handleExportClick = () => {
    const container = document.getElementById('csvTable'); // Change to the ID or class of your container div
    const title = currentTitle;
    exportTableToCSV(container.querySelectorAll('tr'), title);
  };

  const showCSVButton = (currentTitle) => {
    if (currentTitle === 'Select results') {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    showCSVButton(currentTitle);
  }, [currentTitle]);

  return (
    <div className="text-end">
      {isVisible ? (
        <button
          className="btn btn-primary csvDownload"
          onClick={() => handleExportClick()}
        >
          Download CSV
        </button>
      ) : null}
    </div>
  );
};

export default ResultsCSV;
