import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { firestore } from './Firebase';
import ResultsHeader from './ResultsHeader';
import ResultsNav from './ResultsNav';
import ResultsCSV from './ResultsCSV';

const Results = () => {
  const [isSurvey, setIsSurvey] = useState(true);
  const [isDoctor, setIsDoctor] = useState(false);
  const [activeDoctor, setActiveDoctor] = useState('');
  const [activeSurvey, setActiveSurvey] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [currentTitle, setCurrentTitle] = useState('Select results');
  const [lastSelectedSurveyId, setLastSelectedSurveyId] = useState('');
  const [lastSelectedDoctorName, setLastSelectedDoctorName] = useState('');

  const classActiveSurvey = (surveyId) =>
    activeSurvey === surveyId ? 'bg-warning' : 'bg-primary';
  const classActiveDoctor = (doctorId) =>
    activeDoctor === doctorId ? 'bg-warning' : 'bg-primary';

  const handleActiveSurvey = async (surveyId) => {
    setActiveSurvey(surveyId);

    const surveyDataRef = firestore
      .collection('surveys')
      .doc(surveyId)
      .collection('results');
    const surveyDataSnapshot = await surveyDataRef.get();

    const surveyDataArray = surveyDataSnapshot.docs.map((doc) => doc.data());
    setSelectedSurvey(surveyDataArray);

    setLastSelectedSurveyId(surveyId);
    setCurrentTitle(surveyId);
  };

  useEffect(() => {
    const unsubscribeSurveys = firestore
      .collection('surveys')
      .onSnapshot((snapshot) => {
        const firestoreSurveys = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSurveys(firestoreSurveys);
      });

    const unsubscribeDoctors = firestore
      .collection('doctors')
      .orderBy('created_at', 'asc')
      .onSnapshot((snapshot) => {
        const firestoreDoctors = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDoctors(firestoreDoctors);
      });

    return () => {
      unsubscribeDoctors();
      unsubscribeSurveys();
    };
  }, []);

  const handleBySurveyClick = () => {
    setIsSurvey(true);
    setIsDoctor(false);
    if (lastSelectedSurveyId) {
      setCurrentTitle(lastSelectedSurveyId);
    } else {
      setCurrentTitle('Select results');
    }
  };

  const handleByDoctorClick = () => {
    setIsSurvey(false);
    setIsDoctor(true);
    if (lastSelectedDoctorName) {
      setCurrentTitle(lastSelectedDoctorName);
    } else {
      setCurrentTitle('Select results');
    }
  };

  const classIsSurvey = isSurvey ? 'active bg-primary text-white' : 'pe-auto';
  const classIsDoctor = isDoctor ? 'active bg-primary text-white' : 'pe-auto';

  const handleActiveDoctor = async (doctorId, name = '') => {
    setActiveDoctor(doctorId);

    const surveysRef = firestore.collection('surveys');
    const resultsArray = [];

    try {
      const querySnapshot = await surveysRef.get();
      const promises = [];

      querySnapshot.forEach((doc) => {
        const resultsRef = doc.ref.collection('results');
        const query = resultsRef.where('doctorId', '==', doctorId);

        const promise = query
          .get()
          .then((resultQuerySnapshot) => {
            resultQuerySnapshot.forEach((resultDoc) => {
              const resultData = resultDoc.data();

              resultsArray.push({
                surveyId: doc.id,
                resultId: resultDoc.id,
                doctorId: doctorId,
                inpatient: resultData.inpatient,
                outpatient: resultData.outpatient,
                imaging: resultData.imaging,
                procedures: resultData.procedures,
                comments: resultData.comments,
              });
            });
          })
          .catch((error) => {
            console.error('Error getting result documents: ', error);
          });

        promises.push(promise);
      });

      await Promise.all(promises);

      setSelectedDoctor(resultsArray);
    } catch (error) {
      console.error('Error getting survey documents: ', error);
    }
    setLastSelectedDoctorName(name);
    setCurrentTitle(name);
  };

  return (
    <Container>
      <ResultsHeader currentTitle={currentTitle} />
      <Row>
        <div className="d-flex flex-wrap">
          <ResultsNav
            isSurvey={isSurvey}
            classIsSurvey={classIsSurvey}
            classIsDoctor={classIsDoctor}
            handleBySurveyClick={handleBySurveyClick}
            handleByDoctorClick={handleByDoctorClick}
            surveys={surveys}
            doctors={doctors}
            classActiveSurvey={classActiveSurvey}
            classActiveDoctor={classActiveDoctor}
            handleActiveSurvey={handleActiveSurvey}
            setLastSelectedSurveyId={setLastSelectedSurveyId}
            handleActiveDoctor={handleActiveDoctor}
            setLastSelectedDoctorName={setLastSelectedDoctorName}
          />
          <Col className="flex-grow-1 ms-3">
            <ResultsCSV currentTitle={currentTitle} />
            <table id="csvTable" className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>{currentTitle}</th>
                  <th>inpatient</th>
                  <th>outpatient</th>
                  <th>imaging</th>
                  <th>procedures</th>
                  <th>comments</th>
                </tr>
              </thead>
              {isSurvey ? (
                <tbody>
                  {selectedSurvey.length > 0 ? (
                    selectedSurvey.map((item, index) => {
                      return item ? (
                        <tr key={index}>
                          <td>{item.name || ''}</td>
                          <td>{item.inpatient || ''}</td>
                          <td>{item.outpatient || ''}</td>
                          <td>{item.imaging || ''}</td>
                          <td>{item.procedures || ''}</td>
                          <td>{item.comments || ''}</td>
                        </tr>
                      ) : null;
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={6}>
                        Select survey on the left.
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  {selectedDoctor.length > 0 ? (
                    <>
                      {selectedDoctor.map((item, index) => (
                        <tr key={index}>
                          <td>{item.surveyId || ''}</td>
                          <td>{item.inpatient || ''}</td>
                          <td>{item.outpatient || ''}</td>
                          <td>{item.imaging || ''}</td>
                          <td>{item.procedures || ''}</td>
                          <td>{item.comments || ''}</td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={6}>
                        Select doctor on the left.
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default Results;
