import React from 'react';
import { Col } from 'react-bootstrap';

const ResultsNav = ({
  isSurvey,
  classIsSurvey,
  classIsDoctor,
  handleBySurveyClick,
  handleByDoctorClick,
  surveys,
  doctors,
  classActiveSurvey,
  classActiveDoctor,
  handleActiveSurvey,
  setLastSelectedSurveyId,
  handleActiveDoctor,
  setLastSelectedDoctorName,
}) => {
  return (
    <Col className="flex-grow-0">
      <div className="nav nav-tabs mt-2">
        <div
          className={`nav-item nav-link text-nowrap ${classIsSurvey}`}
          onClick={handleBySurveyClick}
          style={{ cursor: 'pointer' }}
        >
          By Survey
        </div>
        <div
          className={`nav-item nav-link ${classIsDoctor}`}
          onClick={handleByDoctorClick}
          style={{ cursor: 'pointer' }}
        >
          By Doctor
        </div>
      </div>
      {isSurvey ? (
        <div>
          {surveys.map((item, index) => (
            <div key={item.id} className="mt-2">
              <button
                className={`btn text-white mx-4 my-2 ${classActiveSurvey(
                  item.id
                )}`}
                onClick={() => {
                  handleActiveSurvey(item.id);
                  setLastSelectedSurveyId(item.id);
                }}
                style={{ minWidth: 200 }}
              >
                {item.id}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {doctors.map((item, index) => (
            <div key={item.id} className="mt-2">
              <button
                className={`btn text-white mx-4 my-2 ${classActiveDoctor(
                  item.id
                )}`}
                onClick={() => {
                  handleActiveDoctor(item.id, item.name);
                  setLastSelectedDoctorName(item.name);
                }}
                style={{ minWidth: 200 }}
              >
                {item.name}
              </button>
            </div>
          ))}
        </div>
      )}
    </Col>
  );
};

export default ResultsNav;
