import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { firestore } from './Firebase';
import Rating from 'react-rating-stars-component';
import { toast, Toaster } from 'react-hot-toast';
import ThankYou from './ThankYou.js';

const Survey = () => {
  const [doctors, setDoctors] = useState([]);
  const [ratings, setRatings] = useState({});
  const [focusedTextarea, setFocusedTextarea] = useState(null);
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);

  const handleFocus = (doctorId) => {
    setFocusedTextarea(doctorId);
  };

  const handleBlur = () => {
    setFocusedTextarea(null);
  };

  const handleRatingChange = (doctorId, ratingName, value) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [doctorId]: {
        ...prevRatings[doctorId],
        [ratingName]: value,
      },
    }));
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const formattedDateTime = currentDateTime
    .toISOString()
    .slice(0, 19)
    .replace('T', ' ');

  const intervalIdRef = useRef(null);

  useEffect(() => {
    const unsubscribe = firestore
      .collection('doctors')
      .orderBy('created_at', 'asc')
      .onSnapshot((snapshot) => {
        const firestoreDoctors = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const initialRatings = {};
        firestoreDoctors.forEach((doctor) => {
          initialRatings[doctor.id] = {
            inpatient: 0,
            outpatient: 0,
            imaging: 0,
            procedures: 0,
            comments: '',
          };
        });

        setDoctors(firestoreDoctors);
        setRatings(initialRatings);
      });

    intervalIdRef.current = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalIdRef.current);
      unsubscribe();
    };
  }, []);

  const handleSubmission = async () => {
    try {
      const batch = firestore.batch();
      let isEmptySubmission = false;

      doctors.forEach((doctor) => {
        const inpatient = ratings[doctor.id]?.inpatient;
        const outpatient = ratings[doctor.id]?.outpatient;
        const imaging = ratings[doctor.id]?.imaging;
        const procedures = ratings[doctor.id]?.procedures;
        const comments = ratings[doctor.id]?.comments;

        if (
          inpatient === undefined ||
          outpatient === undefined ||
          imaging === undefined ||
          procedures === undefined ||
          comments === undefined ||
          inpatient === 0 ||
          outpatient === 0 ||
          imaging === 0 ||
          procedures === 0
        ) {
          isEmptySubmission = true;
        } else {
          const surveys = firestore.collection('surveys');
          const surveyRef = surveys.doc(formattedDateTime);
          surveyRef.set({});

          const resultsRef = surveyRef.collection('results').doc();
          batch.set(resultsRef, {
            doctorId: doctor.id,
            name: doctor.name,
            inpatient,
            outpatient,
            imaging,
            procedures,
            comments,
            timestamp: formattedDateTime,
          });
        }
      });

      if (isEmptySubmission) {
        toast.error('Please provide all ratings before submitting.');
      } else {
        await batch.commit();
        toast.success('Survey submitted successfully.');

        setIsSurveySubmitted(true);

        const initialRatings = {};
        doctors.forEach((doctor) => {
          initialRatings[doctor.id] = {
            inpatient: 0,
            outpatient: 0,
            imaging: 0,
            procedures: 0,
            comments: '',
          };
        });

        setRatings(initialRatings);
      }
    } catch (error) {
      console.error('Error submitting survey:', error);
    }
  };

  return (
    <>
      {!isSurveySubmitted ? (
        <Container className="survey">
          <Row>
            <Toaster />
            <Col>
              <div className="h1 mt-2 text-center">
                Anonymous Feedback Survey
              </div>

              {doctors.map((item, index) => (
                <Row
                  key={item.id}
                  className="mb-2 pb-1"
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#f2f2f2',
                  }}
                >
                  <Col className="text-start d-lg-flex justify-content-evenly align-items-center">
                    <div className="h5">{item.name}</div>

                    <div>
                      <div className="h6">
                        inpatient - {ratings[item.id]?.inpatient}
                      </div>
                      <Rating
                        count={5}
                        value={ratings[item.id]?.inpatient}
                        onChange={(value) =>
                          handleRatingChange(item.id, 'inpatient', value)
                        }
                        size={20}
                        activeColor="#ffd700"
                      />
                    </div>

                    <div>
                      <div className="h6">
                        outpatient - {ratings[item.id]?.outpatient}
                      </div>
                      <Rating
                        count={5}
                        value={ratings[item.id]?.outpatient}
                        onChange={(value) =>
                          handleRatingChange(item.id, 'outpatient', value)
                        }
                        size={20}
                        activeColor="#ffd700"
                      />
                    </div>
                    <div>
                      <div className="h6">
                        imaging - {ratings[item.id]?.imaging}
                      </div>
                      <Rating
                        count={5}
                        value={ratings[item.id]?.imaging}
                        onChange={(value) =>
                          handleRatingChange(item.id, 'imaging', value)
                        }
                        size={20}
                        activeColor="#ffd700"
                      />
                    </div>
                    <div>
                      <div className="h6">
                        procedures - {ratings[item.id]?.procedures}
                      </div>
                      <Rating
                        count={5}
                        value={ratings[item.id]?.procedures}
                        onChange={(value) =>
                          handleRatingChange(item.id, 'procedures', value)
                        }
                        size={20}
                        activeColor="#ffd700"
                      />
                    </div>

                    <div>
                      <textarea
                        className="form-control"
                        placeholder={
                          focusedTextarea === item.id ? '' : 'comments'
                        }
                        onFocus={() => handleFocus(item.id)}
                        onBlur={handleBlur}
                        value={ratings[item.id]?.comments || ''}
                        onChange={(e) =>
                          handleRatingChange(
                            item.id,
                            'comments',
                            e.target.value
                          )
                        }
                      ></textarea>
                    </div>
                  </Col>
                </Row>
              ))}
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleSubmission}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="completed">
          <ThankYou />
        </Container>
      )}
    </>
  );
};

export default Survey;
