import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAeqQQR1_LQry8Ezaxf7eoHESUGaIlc0l0",
  authDomain: "react6-bb25b.firebaseapp.com",
  projectId: "react6-bb25b",
  storageBucket: "react6-bb25b.appspot.com",
  messagingSenderId: "759498948928",
  appId: "1:759498948928:web:a8a45324a8dcde73a8faa5",
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
