import logo from './logo.svg';
import './App.css';
import { Link, Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.min.css';
import Survey from './components/Survey';
import Admin from './components/Admin';
import Results from './components/Results';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = 'Anonymous Feedback Survey';
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Survey />}></Route>
        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/results" element={<Results />}></Route>
      </Routes>
    </>
  );
}

export default App;
